@font-face {
  font-family: "ModernEra";
  src: url("./assets/fonts/ModerEra/ModernEraRegular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "ModernEraMono";
  src: url("./assets/fonts/ModerEra/ModernEraMonoRegular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "ModernEraMonoBold";
  src: url("./assets/fonts/ModerEra/ModernEraMonoBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "ModernEraBold";
  src: url("./assets/fonts/ModerEra/ModernEraBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "ModernEra-ExtraBold";
  src: url("./assets/fonts/ModerEra/ModernEra-ExtraBold.woff");
  font-display: swap;
}

@font-face {
  font-family: "CooperBT";
  src: url("./assets/fonts/Cooper/CooperLtBT-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "QuincyCF-Bold";
  src: url("./assets/fonts/Quincy/QuincyCF-Bold.woff");
  font-display: swap;
}

@font-face {
  font-family: 'Poppins Regular';
  src: url("./assets/fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: 'Poppins Bold';
  src: url("./assets/fonts/Poppins/Poppins-Bold.ttf");
}
